import { Artwork } from '../models/api-models';
import {
	ArtworkDataSection,
	DateFormatType,
	Showroom,
	ShowroomArtwork,
	ShowroomArtworkPreview,
} from '../../models';
import { ShowroomArtworkAuthor } from '../../models/showroom/showroom-artwork/showroom-artwork-author.model';

export const mapShowroomArtworkToAlbumArtwork = (
	showroomArtwork: ShowroomArtwork,
	showroom: Showroom,
	offset: number
): Artwork => {
	return {
		_id: showroomArtwork.collectId,
		albumUuid: showroom.id,
		titles: showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].titles.map(
			(title: string) => ({
				value: title,
				simplifiedValue: title,
			})
		) || [{ value: 'Unknown', simplifiedValue: 'Unknown' }],
		previews: showroomArtwork.data.previews
			.filter((preview) => preview.selected)
			.map((preview: ShowroomArtworkPreview) => ({
				assetId: preview.id,
				nano: preview.id+'/nano',
				small: preview.id+'/small',
				hd: preview.id,
				dimensions: {
					width: 50,
					height: 150,
				},
			})),
		artworkOffset: offset,
		authors: showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].authors.map(
			(author: ShowroomArtworkAuthor) => ({
				scope: 'local',
				name: {
					first: '',
					last: '',
					real: '',
					full: author.nameAndSurname,
					simplified: '',
				},
			})
		) || [
			{
				scope: 'local',
				name: {
					first: '',
					last: '',
					real: '',
					full: 'Unknown',
					simplified: '',
				},
			},
		],
		creation:
			showroom.globalArtworksConfig.displayedSections.basicDetails &&
			showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].creation
				? {
						country: {
							name:
								showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].creation!
									.country || '',
							alpha2Code: '',
						},
						date: {
							start: showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS]
								.creation!.start || {
								format: DateFormatType.DAY_MONTH_YEAR,
								value: '',
								ad: false,
							},
							end: showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS]
								.creation!.end || {
								format: DateFormatType.DAY_MONTH_YEAR,
								value: '',
								ad: false,
							},
							range: !!showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS]
								.creation!.range,
							circa: !!showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS]
								.creation!.circa,
						},
						city: {
							country:
								showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].creation!
									.country || '',
							state: '',
							localized_country_name: '',
							city:
								showroomArtwork.data[ArtworkDataSection.BASIC_DETAILS].creation!
									.city || '',
						},
				  }
				: {
						country: {
							name: '',
							alpha2Code: '',
						},
						date: {
							start: {
								format: DateFormatType.DAY_MONTH_YEAR,
								value: '',
								ad: false,
							},
							end: {
								format: DateFormatType.DAY_MONTH_YEAR,
								value: '',
								ad: false,
							},
							range: false,
							circa: false,
						},
						city: {
							country: '',
							state: '',
							localized_country_name: '',
							city: '',
						},
				  },
		tags:
			showroom.globalArtworksConfig.displayedSections.materialsTechniques &&
			showroomArtwork.data[ArtworkDataSection.MATERIALS_TECHNIQUES]
				? showroomArtwork.data[ArtworkDataSection.MATERIALS_TECHNIQUES]!.tags
				: [],
		sign: showroom.globalArtworksConfig.displayedSections.inscriptionMarks
			? {
					isSigned: !!(
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS] &&
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!.isSigned
					),
					byHand: !!(
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS] &&
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!.byHand
					),
					stamped: !!(
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS] &&
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!.stamped
					),
					engraved: !!(
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS] &&
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!.engraved
					),
					signedInThePlate: !!(
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS] &&
						showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!
							.signedInThePlate
					),
					notes: showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]
						? showroomArtwork.data[ArtworkDataSection.INSCRIPTION_MARKS]!.notes
						: null,
			  }
			: {
					isSigned: false,
					byHand: false,
					stamped: false,
					engraved: false,
					signedInThePlate: false,
					notes: null,
			  },
		dimensions: showroom.globalArtworksConfig.displayedSections.dimensions
			? showroomArtwork.data[ArtworkDataSection.DIMENSIONS]
			: null,
		price: showroomArtwork.config.retailPrice,
		inquireEmail: showroomArtwork.config.inquireButton
			? showroomArtwork.config.inquireButton.inquireEmail
			: null,
		buyLink: showroomArtwork.config.buyButton
			? showroomArtwork.config.buyButton.buyLink
			: null,
		provenance: showroom.globalArtworksConfig.displayedSections.provenance
			? showroomArtwork.data[ArtworkDataSection.PROVENANCE]
			: null,
		literature: showroom.globalArtworksConfig.displayedSections.literature
			? showroomArtwork.data[ArtworkDataSection.LITERATURE]
			: null,
		exhibitions: showroom.globalArtworksConfig.displayedSections.exhibitions
			? showroomArtwork.data[ArtworkDataSection.EXHIBITIONS]
			: null,
		description: showroom.globalArtworksConfig.displayedSections.description
			? showroomArtwork.data[ArtworkDataSection.DESCRIPTION]
			: null,
		status: showroomArtwork.config.status,
		edition: showroom.globalArtworksConfig.displayedSections.edition
			? showroomArtwork.data.edition && !showroomArtwork.data.edition.isUnique
				? {
						isArtistProof: showroomArtwork.data.edition?.isArtistProof || null,
						isUnique: showroomArtwork.data.edition?.isUnique || false,
						notes: showroomArtwork.data.edition?.notes || null,
						number: showroomArtwork.data.edition?.number || null,
						total: showroomArtwork.data.edition?.total || null,
				  }
				: null
			: null,
	};
};
